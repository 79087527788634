// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-ceramic-steel-vs-glassboard-js": () => import("./../../../src/pages/ceramic-steel-vs-glassboard.js" /* webpackChunkName: "component---src-pages-ceramic-steel-vs-glassboard-js" */),
  "component---src-pages-dash-js": () => import("./../../../src/pages/dash.js" /* webpackChunkName: "component---src-pages-dash-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-limitless-js": () => import("./../../../src/pages/limitless.js" /* webpackChunkName: "component---src-pages-limitless-js" */),
  "component---src-pages-rapid-js": () => import("./../../../src/pages/rapid.js" /* webpackChunkName: "component---src-pages-rapid-js" */),
  "component---src-pages-sports-js": () => import("./../../../src/pages/sports.js" /* webpackChunkName: "component---src-pages-sports-js" */),
  "component---src-pages-terms-privacy-js": () => import("./../../../src/pages/terms-privacy.js" /* webpackChunkName: "component---src-pages-terms-privacy-js" */)
}

